
















import { Component, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'
import DatePicker from 'vue2-datepicker'
import moment from 'moment/moment';

@Component({
  components: {
    DatePicker
  }
})
export default class DateField extends AbstractField {
  dateValue: Date | null = null

  @Watch('dateValue') onDateValueChange() {
    this.value = moment(this.dateValue).format('YYYY-MM-DD')
  }

  created(): void {
    this.dateValue = new Date(this.value)
  }
}
